import { PrefabProvider } from '@prefab-cloud/prefab-cloud-react';

import { ConfettiProvider } from '@/lib/contexts/ConfettiProvider';
import { useAuth } from '@/lib/hooks/useAuth';
import { isSessionExpired } from '@/lib/utils';
import { SessionExpiry } from '@/ui/SessionExpiry';
import { SideNav } from '@/ui/SideNav';
import { StickyBanner } from '@/ui/StickyBanner';

import { HelpscoutBeacon } from './HelpscoutBeacon';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { user, session } = useAuth();
  const prefabApiKey = process.env.NEXT_PUBLIC_PREFAB_API_KEY as string;
  const prefabContext = {
    user: {
      key: user?.id || 'anonymous',
      email: user?.email || '',
      role: user?.role || '',
    },
  };

  return session ? (
    <PrefabProvider
      contextAttributes={prefabContext}
      apiKey={prefabApiKey}
      collectEvaluationSummaries={true}
    >
      <ConfettiProvider>
        <div className="flex w-full max-w-full flex-col md:h-screen md:max-h-screen md:flex-row">
          <SideNav user={user} />

          <main className="flex size-full grow flex-col overflow-auto bg-gray-100 print:overflow-visible">
            {children}
          </main>

          {user && session.impersonating && (
            <StickyBanner
              onCloseLinkTo="/sign_out"
              message={`Impersonating ${user.name}`}
            />
          )}

          {session && <SessionExpiry expired={isSessionExpired(session)} />}
        </div>
        <HelpscoutBeacon />
      </ConfettiProvider>
    </PrefabProvider>
  ) : null;
}
