type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;

/**
 * A type guard that checks if a value is truthy
 *
 * @param value A value to test for truthiness
 * @returns true if the value is truthy, false otherwise
 */
export function isTruthy<T>(value: T): value is Truthy<T> {
  return Boolean(value);
}
