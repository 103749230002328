import { Provider } from '@/types/core/Provider';
import { User } from '@/types/core/User';
import { UserRole } from '@/types/core/UserRole';

import { isPrescriber } from '../user';

type ChannelType = 'md' | 'rd' | 'support';
type ProviderOrUser = Provider | User;

export function getPrimaryChannelType(provider: ProviderOrUser): ChannelType {
  if (isPrescriber(provider.role)) {
    return 'md';
  }
  if (provider.role === UserRole.RD) {
    return 'rd';
  }
  return 'support';
}
