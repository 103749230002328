import { UserNotificationEvent } from '@/types/core/UserNotification';

const summaries = {
  'task.completed': {
    one: 'task was completed',
    many: 'tasks were completed',
  },
  'task_comment.created': {
    one: 'comment added to a task',
    many: 'comments added to tasks',
  },
  'chat_message.received': {
    one: 'patient with unread messages',
    many: 'patients with unread messages',
  },
  'urgent_chat_message.received': {
    one: 'patient with urgent messages',
    many: 'patients with urgent messages',
  },
  'scheduling.md_interval.updated': {
    one: "patient's scheduling interval changed",
    many: "patients' scheduling intervals changed",
  },
  'care_team_coverage.created': {
    one: 'new care team coverage',
    many: 'new care team coverages',
  },
  'care_team_coverage.canceled': {
    one: 'care team coverage was canceled',
    many: 'care team coverages were canceled',
  },
} as const;

function getSummaryDescription(event: UserNotificationEvent, count: number) {
  const summary = summaries[event];
  if (summary) {
    return count > 1 ? summary.many : summary.one;
  }
  return event;
}

export function getNotificationGroupSummary(
  event: UserNotificationEvent,
  count: number,
) {
  return `${count} ${getSummaryDescription(event, count)}`;
}
